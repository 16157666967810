<template>
  <div style="line-height: 1rem; display: inline-block; margin-bottom: 1rem" >
    
    <div class="item-line"  @click="openFB()">
      <v-img class="icon-rotate icon-ig" :src="logo_facebook" />
      {{fb}}
    </div>
    <div class="item-line"  @click="openIG()">
      <v-img class="icon-rotate icon-ig" :src="logo_instagram" />
      {{ig}}
    </div>
  </div>
</template>

<script>
import logo_instagram from "@/assets/logo-instagram.svg";
import logo_facebook from "@/assets/logo-facebook.svg";

export default {
  props: {
    ig: {
      type: String,
      required: true,
    },
    fb: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    logo_instagram,
    logo_facebook
  }),

  methods: {
    openIG: function () {
      let url="https://www.instagram.com/"+ this.ig
      window.open(url);
    },
    openFB: function () {
      let url="https://www.facebook.com/"+ this.fb
      window.open(url);
    },
  },
};
</script>

<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
    font-size: 2em;
  }
  50% {
    transform: rotate(10deg);
    font-size: 2em;
  }
  100% {
    transform: rotate(0deg);
    font-size: 2em;
  }
}
.item-line {
  line-height: 1rem;
  height: 1rem;
  display: block;
  align-items: center;
  margin: 1rem 0.8rem;
  margin-left: 0;
  text-align: left;
}

.icon-ig {
  width: 1.5rem;
  height: auto;
  margin: 0.3em;
}
.icon-telegram {
  width: 1.5rem;
  height: auto;
  margin: 0.4em;
}

.icon-rotate {
  animation: rotation infinite 2s linear;
  display: inline-flex;

  background-size: contain !important;
}
@media screen and (max-width: 1264px) {
  .item-line {
    margin: 1rem 0.8rem;
  }
}
</style>
