<template>
  <v-card class="mx-auto card-shandows card-body card-intespace" style="min-height: 550px;">
    <div style="display: flex; justify-content: center">
      <div class="center-image out-card">
        <v-img contain :src="card.imagem" class="my-10" />
      </div>
    </div>
    <v-card-text class="card-title text-center">
      {{ card.titulo }}
    </v-card-text>
    <v-card-text class="card-subtitle text-center">
      {{ card.subtitulo }}
    </v-card-text>
    <v-card-subtitle class="card-text">
      <slot name="texto" />
    </v-card-subtitle>
  </v-card>
</template>

<script lang="js">
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

.card-title {
  flex: 1;
  margin-top: 14px;
  font-size: 2rem;
  font-weight: 600;
  color: #e2f5fd !important;
  line-height: 0.2rem;
}
.card-subtitle {
  flex: 1;
  margin-top: 12px;
  font-size: 1.82rem;
  font-weight: 400;
  color: #e2f5fd !important;
  line-height: 0.15rem;
}
.card-text {
  line-height: 1.6rem;
  font-size: 0.95em;
  text-align: justify;
  padding: 2em;
  font-family: Verdana, sans-serif;
  color: #e2f5fd !important;
}
.center-image {
  display: flex;
  place-content: center;
  background-color: #f9b02c;
  margin: 0px 12% 0% 10%;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: #fff 2px solid;
  box-shadow: 0px 1px 20px 8px #ffba00;
}

.out-card {
  margin-top: -20%;
}

.card-shandows {
  box-shadow: 19px 18px 17px 3px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.card-body {
  background: rgb(236,235,255);
  color: #e2f5fd;
  background: linear-gradient(61deg, #08090c 0%, #182946 36%, #123b7c 100%);
}

@media screen and (max-width: 1264px) {
  .card-intespace {
    margin: 6rem;
  }
}


</style>
