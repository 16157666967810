

























































// import businessintelligence from "../assets/businessintelligence.png";
// import management from "../assets/management.png";
// import paperplane from "../assets/paper-plane.png";
import contact from "../assets/contact.png";
// import OnneHomeCardItem from "@/components/OnneHomeCardItem.vue";
export default {
  data: () => {
    return {
      imagem: contact,
    };
  },
};
