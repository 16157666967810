













import Vue from "vue";
import logo from "@/assets/logo.svg";

export default Vue.extend({
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    logo,
  }),
});
