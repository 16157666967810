import { render, staticRenderFns } from "./OnneContatoInstagram.vue?vue&type=template&id=0a7284ad&scoped=true&"
import script from "./OnneContatoInstagram.vue?vue&type=script&lang=js&"
export * from "./OnneContatoInstagram.vue?vue&type=script&lang=js&"
import style0 from "./OnneContatoInstagram.vue?vue&type=style&index=0&id=0a7284ad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7284ad",
  null
  
)

export default component.exports