









import Vue from "vue";
import logo from "@/assets/logo.svg";

export default Vue.extend({
  data: () => ({
    logo,
  }),
});
