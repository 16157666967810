/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
<template>
  <div style="line-height: 1rem; display: inline-block; margin-bottom: 1rem">
    <div class="item-line">
      <v-icon style="color: #fff;padding: 6px;">mdi-phone</v-icon>
      {{ telefone }}
    </div>
    <div class="item-line" @click="openWhatsapp()" >
      <v-img class="icon-rotate icon-whatsapp" :src="logo_whatsapp" />
      WhatsApp
    </div>
    <div class="item-line"  @click="openTelegram()" >
      <v-img class="icon-rotate icon-telegram" :src="logo_telegram" />
      Telegram
    </div>
  </div>
</template>

<script>
import logo_telegram from "@/assets/logo_telegram.svg";
import logo_whatsapp from "@/assets/logo_whatsapp.svg";

export default {
  props: {
    telefone: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    logo_telegram,
    logo_whatsapp
  }),

  methods: {
    openWhatsapp: function () {
      let fone = '55'+ this.telefone.replace(/\s/g, '');
      let url="https://api.whatsapp.com/send?phone=" + fone;
      window.open(url);
    },
    openTelegram: function () {
      let url = "https://t.me/RodrigoMichel";
      window.open(url);
    },
  },
};
</script>

<style scoped>
@keyframes rotation {
  0% {
    transform: rotate(0deg);
    font-size: 2em;
  }
  50% {
    transform: rotate(10deg);
    font-size: 2em;
  }
  100% {
    transform: rotate(0deg);
    font-size: 2em;
  }
}
.item-line {
  line-height: 1rem;
  height: 1rem;
  display: block;
  align-items: center;
  margin: 1rem 0.8rem;
  margin-left: 0;
  text-align: left;
}

.icon-whatsapp {
  width: 1.5rem;
  height: auto;
  margin: 0.3em;
}
.icon-telegram {
  width: 1.5rem;
  height: auto;
  margin: 0.4em;
}

.icon-rotate {
  animation: rotation infinite 2s linear;
  display: inline-flex;

  background-size: contain !important;
}
@media screen and (max-width: 1264px) {
  .item-line {
    margin: 1rem 0.8rem;
  }
}
</style>
