














































import Vue from "vue";

export default Vue.extend({
  data: () => {
    return {
      menuDisponivel: [
        {
          titulo: "Início",
          nome_rota: "home",
          icone: "mdi-home",
        },
        {
          titulo: "Sobre",
          nome_rota: "sobre",
          icone: "mdi-format-list-text",
        },
        //,{
        //  titulo: "Serviços",
        //  nome_rota: "servico",
        //  icone: "mdi-tools",
        //},
      ],
    };
  },
  computed: {
    windowsWith: (): number => screen.width,
  },
});
