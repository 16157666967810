import { render, staticRenderFns } from "./OnneContatoEmail.vue?vue&type=template&id=39f21716&scoped=true&"
import script from "./OnneContatoEmail.vue?vue&type=script&lang=ts&"
export * from "./OnneContatoEmail.vue?vue&type=script&lang=ts&"
import style0 from "./OnneContatoEmail.vue?vue&type=style&index=0&id=39f21716&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f21716",
  null
  
)

export default component.exports