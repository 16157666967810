<template>
  <li>
    <span class="bold">{{titulo}}</span>
    <div>{{texto}}</div>
  </li>
</template>

<script>
export default {
  props: ["titulo", "texto"],
}
</script>

<style scoped>
.bold {
  font-weight: 800;
}
</style>
