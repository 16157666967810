










export default {
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data(): any {
    return {
    }
  },
  methods: { },
};
